import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { UniIcon } from "components/Logo/UniIcon";
import Web3Status from "components/Web3Status";
import { chainIdToBackendName } from "graphql/data/util";
import { useDisableNFTRoutes } from "hooks/useDisableNFTRoutes";
import { useIsLandingPage } from "hooks/useIsLandingPage";
import { useIsNftPage } from "hooks/useIsNftPage";
import { useIsPoolsPage } from "hooks/useIsPoolsPage";
import { Box } from "nft/components/Box";
import { Row } from "nft/components/Flex";
import { useProfilePageState } from "nft/hooks";
import { ProfilePageStateType } from "nft/types";
import { GetTheAppButton } from "pages/Landing/components/DownloadApp/GetTheAppButton";
import { ReactNode, useCallback } from "react";
import {
  NavLink,
  NavLinkProps,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

import { useAccountDrawer } from "components/AccountDrawer/MiniPortfolio/hooks";
import { Z_INDEX } from "theme/zIndex";
import { useIsNavSearchInputVisible } from "../../nft/hooks/useIsNavSearchInputVisible";
import { Bag } from "./Bag";
import Blur from "./Blur";
import { ChainSelector } from "./ChainSelector";
import { More } from "./More";
import * as styles from "./style.css";
import { BREAKPOINTS } from "theme";

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  z-index: ${Z_INDEX.sticky};
  display: flex;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex-direction: column;
  }
`;

interface MenuItemProps {
  href: string;
  id?: NavLinkProps["id"];
  isActive?: boolean;
  children: ReactNode;
  dataTestId?: string;
  external?: boolean;
  comingSoon?: boolean;
}

const MenuItem = ({
  href,
  dataTestId,
  id,
  isActive,
  children,
  external,
}: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: "none" }}
      data-testid={dataTestId}
      target={external ? "_blank" : undefined}
    >
      {children}
    </NavLink>
  );
};

const DisabledLink = styled.div`
  position: relative;
  padding: 8px 14px;
  color: #c2c2c2;
  white-space: nowrap;
`

const SoonText = styled('span')`
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    position: static;
    margin-left: 8px;
  }
  position: absolute;
  right: 8px;
  bottom: -10px;
  color: ${({ theme }) => theme.accent1};
`

export const PageTabs = () => {
  const { pathname } = useLocation();
  const { chainId: connectedChainId } = useWeb3React();

  const isPoolActive = useIsPoolsPage();

  return (
    <>
      <MenuItem href="/swap" isActive={pathname.startsWith("/swap")}>
        <Trans>Swap</Trans>
      </MenuItem>
      <MenuItem href="/pools/v2" isActive={isPoolActive}>
        <Trans>Pools</Trans>
      </MenuItem>
      <MenuItem href="https://rewards.neptunex.io/" external>
        Earn
      </MenuItem>
      <DisabledLink>
        Governance
        <SoonText>(soon)</SoonText>
      </DisabledLink>
    </>
  );
};

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage();
  const isLandingPage = useIsLandingPage();
  const sellPageState = useProfilePageState((state) => state.state);
  const navigate = useNavigate();

  const { account } = useWeb3React();
  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer();
  const handleUniIconClick = useCallback(() => {
    if (account) {
      return;
    }
    if (accountDrawerOpen) {
      toggleAccountDrawer();
    }
    navigate({
      pathname: "/",
      search: "?intro=true",
    });
  }, [account, accountDrawerOpen, navigate, toggleAccountDrawer]);

  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box className={styles.leftSideContainer} justifyContent={{ sm: 'center', md: 'flex-start' }}>
          <Box className={styles.logoContainer}>
            <UniIcon
              width="215"
              height="42"
              data-testid="uniswap-logo"
              className={styles.logo}
              clickable={!account}
              onClick={handleUniIconClick}
            />
          </Box>
        </Box>
        <Row display={{ sm: "none", lg: "flex" }} alignItems="center">
          <PageTabs />
        </Row>
        <Box className={styles.rightSideContainer}>
          <Row gap="12">
            {isNftPage && sellPageState !== ProfilePageStateType.LISTING && (
              <Bag />
            )}
            <More />
            <ChainSelector />
            {isLandingPage && <GetTheAppButton />}
            <Web3Status />
          </Row>
        </Box>
      </Nav>
    </>
  );
};

export default Navbar;
