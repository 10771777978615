import styled from "styled-components";

type Props = {
  onClose?: () => void;
};

const DisclaimerMessageBox = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 350px;
  background: black;
  border-radius: 24px;
  border: 1px solid #9eff00;
  padding: 8px 16px;
  gap: 8px;
`;

const DisclaimerMessageTitleBox = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 16px;
`;

const DisclaimerMessageTitle = styled.div`
  font-size: 16px;
  color: #9eff00;
  font-weight: 700;
`;

const DisclaimerCloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  color: #9eff00;
  cursor: pointer;
`;

const DisclaimerMessageContent = styled.div`
  font-size: 14px;
`;

export const DisclaimerMessage = ({ onClose }: Props) => {
  return (
    <DisclaimerMessageBox>
      <DisclaimerMessageTitleBox>
        <DisclaimerMessageTitle className="text-sm lg:text-base text-accent font-bold">
          NeptuneX Routing Algorithm v0.3 is now live!
        </DisclaimerMessageTitle>
        {onClose && (
          <DisclaimerCloseButton onClick={onClose}>X</DisclaimerCloseButton>
        )}
      </DisclaimerMessageTitleBox>
      <DisclaimerMessageContent>
        Performance upgrade is complete. Your feedback is important to us.
        Although updated, NeptuneX is still under constant development.
        Thanks!👏
      </DisclaimerMessageContent>
    </DisclaimerMessageBox>
  );
};
